import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { withTheme } from "emotion-theming"

import Layout from "./layout"
import SEO from "./seo"
import Wrapper from "./blog-wrapper"

class BlogBody extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const { location } = this.props.data
    const { regularPosts, featuredPosts } = this.props

    const postSlug = node => {
      const title = node.frontmatter.title || node.fields.slug
      const featuredImgFluid =
        node.frontmatter.featuredImage &&
        node.frontmatter.featuredImage.childImageSharp.fluid

      return (
        <div key={node.fields.slug}>
          <h3>
            <Link style={{ boxShadow: `none` }} to={`blog${node.fields.slug}`}>
              {featuredImgFluid ? <Img fluid={featuredImgFluid} /> : title}
            </Link>
          </h3>
          <small>{node.frontmatter.date}</small>
          <p
            dangerouslySetInnerHTML={{
              __html: node.frontmatter.description || node.excerpt,
            }}
          />
        </div>
      )
    }

    return (
      <Layout location={location} title={siteTitle}>
        <Wrapper>
          <SEO title="All posts" />
          <div style={{ margin: "20px 0 40px" }}>
            {featuredPosts.map(({ node }) => postSlug(node))}
          </div>
          <div style={{ margin: "20px 0 40px" }}>
            {regularPosts.map(({ node }) => postSlug(node))}
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

export default withTheme(BlogBody)
